.App-header {
  background-color: #6415ff;
  padding: 20px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.App-header h1 {
  margin: 0;
  font-size: 24px;
}

.logout-button {
  background-color: #ffffff;
  color: #6415ff;
  border: 2px solid #6415ff;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.logout-button:hover {
  background-color: #6415ff;
  color: #ffffff;
}

.form-container {
  margin: 20px auto;
  padding: 20px;
  max-width: 500px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input[type="file"] {
  width: 100%;
}

.form-group select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
}

.submit-button {
  background-color: #6415ff;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.download-link {
  display: block;
  margin-top: 20px;
  color: #6415ff;
  text-decoration: none;
}

.download-link:hover {
  text-decoration: underline;
}

/* Media query for mobile devices */
@media (max-width: 600px) {
  .ReactModal__Content {
    inset: 20px !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .App-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .App-header h1 {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
  }

  .logout-button {
    align-self: center;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
}
/* customNotification.css */
.anticon-close-circle {
  display: none !important;
}
.ant-notification {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 100% !important;
  max-width: 600px !important;
}

.ReactModal__Content.ReactModal__Content--after-open {
  padding: 0 !important;
  overflow: hidden;
}
.ant-notification-notice {
  width: 100% !important;
  max-width: 600px !important;
  text-align: center !important;
  padding: 20px 24px !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
}

.ant-notification-notice-message {
  font-size: 24px !important;
  font-weight: bold !important;
}

.ant-notification-notice-description {
  font-size: 18px !important;
  margin-top: 8px !important;
}

.ant-notification-notice-content {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}
